import { CurrencyCode } from "Api/Api";
import {
  ProductClassCode,
  ProductTradingFrequencyCode,
  ProductIsinDetail,
  ProductsIsin,
} from "Models/Products";

import InvestikaHeaderImage from "Assets/Images/FundHeaders/investika.jpg";
import EfektikaHeaderImage from "Assets/Images/FundHeaders/efektika.jpg";
import DynamikaHeaderImage from "Assets/Images/FundHeaders/dynamika.jpg";
import MonetikaHeaderImage from "Assets/Images/FundHeaders/monetika.jpg";

export const ProductsIsinDetail: { [key in ProductsIsin]: ProductIsinDetail } =
  {
    CZ0008474830: {
      product: "investika",
      code: "IRF",
      color: "#FDE200",
      contrastColor: "#000000",
      class: ProductClassCode.CZK,
      classCode: "CZK",
      tradingFrequency: ProductTradingFrequencyCode.MONTHLY,
      bankId: {
        minSingleInvestmentDeposit: {
          [CurrencyCode.CZK]: 10_000,
          [CurrencyCode.EUR]: 400,
        },
        minPeriodicalInvestmentDeposit: {
          [CurrencyCode.CZK]: 500,
          [CurrencyCode.EUR]: 20,
        },
      },
      headerImage: InvestikaHeaderImage,
    },
    CZ0008475902: {
      product: "investika",
      code: "IRF",
      color: "#FDE200",
      contrastColor: "#000000",
      class: ProductClassCode.EUR,
      classCode: "EUR",
      tradingFrequency: ProductTradingFrequencyCode.MONTHLY,
      bankId: {
        minSingleInvestmentDeposit: {
          [CurrencyCode.CZK]: 10_000,
          [CurrencyCode.EUR]: 400,
        },
        minPeriodicalInvestmentDeposit: {
          [CurrencyCode.CZK]: 500,
          [CurrencyCode.EUR]: 20,
        },
      },
      headerImage: InvestikaHeaderImage,
    },
    CZ0008476314: {
      product: "investika",
      code: "IRF",
      color: "#FDE200",
      contrastColor: "#000000",
      class: ProductClassCode.PROFI_CZK,
      classCode: "CZK investiční",
      tradingFrequency: ProductTradingFrequencyCode.MONTHLY,
      bankId: {
        minSingleInvestmentDeposit: {
          [CurrencyCode.CZK]: 10_000,
          [CurrencyCode.EUR]: 400,
        },
        minPeriodicalInvestmentDeposit: {
          [CurrencyCode.CZK]: 500,
          [CurrencyCode.EUR]: 20,
        },
      },
      headerImage: InvestikaHeaderImage,
    },
    CZ0008477379: {
      product: "monetika",
      code: "MON",
      color: "#0AA5DF",
      contrastColor: "#FFFFFF",
      class: ProductClassCode.CZK,
      classCode: "CZK",
      tradingFrequency: ProductTradingFrequencyCode.DAILY,
      bankId: {
        minSingleInvestmentDeposit: {
          [CurrencyCode.CZK]: 10_000,
          [CurrencyCode.EUR]: 400,
        },
        minPeriodicalInvestmentDeposit: {
          [CurrencyCode.CZK]: 500,
          [CurrencyCode.EUR]: 20,
        },
      },
      headerImage: MonetikaHeaderImage,
    },
    CZ0008477361: {
      product: "monetika",
      code: "MON",
      color: "#0AA5DF",
      contrastColor: "#FFFFFF",
      class: ProductClassCode.PROFI_CZK,
      classCode: "CZK investiční",
      tradingFrequency: ProductTradingFrequencyCode.DAILY,
      bankId: {
        minSingleInvestmentDeposit: {
          [CurrencyCode.CZK]: 10_000,
          [CurrencyCode.EUR]: 400,
        },
        minPeriodicalInvestmentDeposit: {
          [CurrencyCode.CZK]: 500,
          [CurrencyCode.EUR]: 20,
        },
      },
      headerImage: MonetikaHeaderImage,
    },
    CZ0008477650: {
      product: "efektika",
      code: "EFE",
      color: "#FF2B78",
      contrastColor: "#FFFFFF",
      class: ProductClassCode.CZK,
      classCode: "CZK nezajištěná",
      tradingFrequency: ProductTradingFrequencyCode.DAILY,
      bankId: {
        minSingleInvestmentDeposit: {
          [CurrencyCode.CZK]: 10_000,
          [CurrencyCode.EUR]: 400,
        },
        minPeriodicalInvestmentDeposit: {
          [CurrencyCode.CZK]: 500,
          [CurrencyCode.EUR]: 20,
        },
      },
      headerImage: EfektikaHeaderImage,
    },
    CZ0008477676: {
      product: "efektika",
      code: "EFE",
      color: "#FF2B78",
      contrastColor: "#FFFFFF",
      class: ProductClassCode.CZK_Hedged,
      classCode: "CZK zajištěná",
      tradingFrequency: ProductTradingFrequencyCode.DAILY,
      bankId: {
        minSingleInvestmentDeposit: {
          [CurrencyCode.CZK]: 10_000,
          [CurrencyCode.EUR]: 400,
        },
        minPeriodicalInvestmentDeposit: {
          [CurrencyCode.CZK]: 500,
          [CurrencyCode.EUR]: 20,
        },
      },
      headerImage: EfektikaHeaderImage,
    },
    CZ0008477668: {
      product: "efektika",
      code: "EFE",
      color: "#FF2B78",
      contrastColor: "#FFFFFF",
      class: ProductClassCode.PROFI_CZK,
      classCode: "CZK investiční",
      tradingFrequency: ProductTradingFrequencyCode.DAILY,
      bankId: {
        minSingleInvestmentDeposit: {
          [CurrencyCode.CZK]: 10_000,
          [CurrencyCode.EUR]: 400,
        },
        minPeriodicalInvestmentDeposit: {
          [CurrencyCode.CZK]: 500,
          [CurrencyCode.EUR]: 20,
        },
      },
      headerImage: EfektikaHeaderImage,
    },
    CZ0008475670: {
      product: "dynamika",
      code: "DYN",
      color: "#B98EFF",
      contrastColor: "#FFFFFF",
      class: ProductClassCode.FKI_CZK,
      classCode: "CZK",
      tradingFrequency: ProductTradingFrequencyCode.DAILY,
      bankId: {
        minSingleInvestmentDeposit: {
          [CurrencyCode.CZK]: 10_000,
          [CurrencyCode.EUR]: 400,
        },
        minPeriodicalInvestmentDeposit: {
          [CurrencyCode.CZK]: 500,
          [CurrencyCode.EUR]: 20,
        },
      },
      headerImage: DynamikaHeaderImage,
    },
    CZ0008476272: {
      product: "dynamika",
      code: "DYN",
      color: "#B98EFF",
      contrastColor: "#FFFFFF",
      class: ProductClassCode.PROFI_CZK,
      classCode: "CZK investiční",
      tradingFrequency: ProductTradingFrequencyCode.DAILY,
      bankId: {
        minSingleInvestmentDeposit: {
          [CurrencyCode.CZK]: 10_000,
          [CurrencyCode.EUR]: 400,
        },
        minPeriodicalInvestmentDeposit: {
          [CurrencyCode.CZK]: 500,
          [CurrencyCode.EUR]: 20,
        },
      },
      headerImage: DynamikaHeaderImage,
    },
    CZ0008476801: {
      product: "benefika",
      code: "BEN",
      color: "#FFFFFF",
      contrastColor: "#000000",
      class: ProductClassCode.Benefika,
      classCode: null,
      tradingFrequency: ProductTradingFrequencyCode.DAILY,
      bankId: {
        minSingleInvestmentDeposit: {
          [CurrencyCode.CZK]: 10_000,
          [CurrencyCode.EUR]: 400,
        },
        minPeriodicalInvestmentDeposit: {
          [CurrencyCode.CZK]: 500,
          [CurrencyCode.EUR]: 20,
        },
      },
    },
    CZ0008478005: {
      product: "euromonetika",
      code: "EURMON",
      color: "#0AA5DF",
      contrastColor: "#FFFFFF",
      class: ProductClassCode.EUR,
      classCode: "EUR",
      tradingFrequency: ProductTradingFrequencyCode.DAILY,
      bankId: {
        minSingleInvestmentDeposit: {
          [CurrencyCode.CZK]: 10_000,
          [CurrencyCode.EUR]: 400,
        },
        minPeriodicalInvestmentDeposit: {
          [CurrencyCode.CZK]: 500,
          [CurrencyCode.EUR]: 20,
        },
      },
      headerImage: MonetikaHeaderImage,
    },
  };
