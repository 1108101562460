import { ActionType, createAsyncAction, createReducer } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import { FetchStateType } from "State/Models";
import { DocumentResult, getDocumentsFileName } from "Api/Api";

export type GetDownloadStateType = {
  isDownloadAllowed: boolean;
  files: {
    [fileName: string]: FetchStateType<Omit<DocumentResult, "data">>;
  };
};

export const getDownloadState = (): GetDownloadStateType => ({
  isDownloadAllowed: true,
  files: {},
});

export type GetDownloadActionType = ActionType<typeof getDownloadAsync>;

export const getDownloadAsync = createAsyncAction(
  "@documents/GET_DOWNLOAD_REQUEST",
  "@documents/GET_DOWNLOAD_SUCCESS",
  "@documents/GET_DOWNLOAD_FAILURE",
)<
  { contractID?: number | null; fileName: string },
  { fileName: string } & Omit<DocumentResult, "data">,
  { error: Error; fileName: string }
>();

function* getDownload(
  action: ActionType<typeof getDownloadAsync.request>,
): Generator {
  try {
    const response = yield* call(
      getDocumentsFileName,
      action.payload.fileName,
      action.payload.contractID,
    );

    if (response.status === 200) {
      const { data, fileName, fileType, mimeType } = response.json.document!;

      const type = mimeType ?? "";
      const link = document.createElement("a");

      link.href = `data:${type};base64,${data}`;
      link.download = fileName;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      yield put(getDownloadAsync.success({ fileName, fileType }));
    } else {
      yield put(
        getDownloadAsync.failure({
          error: new Error(),
          fileName: action.payload.fileName,
        }),
      );
    }
  } catch (err) {
    yield put(
      getDownloadAsync.failure({
        error: err as Error,
        fileName: action.payload.fileName,
      }),
    );
  }
}

export function* getDownloadSaga() {
  yield takeLatest(getType(getDownloadAsync.request), getDownload);
}

export const getDownloadReducer = createReducer<
  GetDownloadStateType,
  GetDownloadActionType
>(getDownloadState())
  .handleAction(getDownloadAsync.request, (state, action) => ({
    ...state,
    isDownloadAllowed: false,
    files: {
      ...state.files,
      [action.payload.fileName]: { isLoading: true, error: null, data: null },
    },
  }))
  .handleAction(getDownloadAsync.failure, (state, action) => ({
    ...state,
    isDownloadAllowed: true,
    files: {
      ...state.files,
      [action.payload.fileName]: {
        isLoading: false,
        error: action.payload.error,
        data: null,
      },
    },
  }))
  .handleAction(getDownloadAsync.success, (state, action) => ({
    ...state,
    isDownloadAllowed: true,
    files: {
      ...state.files,
      [action.payload.fileName]: {
        isLoading: false,
        error: null,
        data: action.payload,
      },
    },
  }));
